<template>
  <div class="AdminUserDelete">
    <Spacer num="1" />
    <div tcenter>
      {{ $locale["delete_user_confirm"] }}
      <span dcolor>@{{ user.user }}</span>
    </div>
    <Spacer num="2" />
    <Confirm :confirm="confirm" @cancel="modal && modal.close()" @accept="deleteUser" />
  </div>
</template>

<script>
export default {
  props: ["user", "modal", "onDelete"],
  data: function() {
    return {
      axios: {
        headers: {
          "use-form": "false",
        },
      },
    };
  },
  methods: {
    deleteUser: async function() {
      this.isLoading(true);
      await Go.sleep(250);

      try {
        this.body = { userId: this.user._id, userName: this.user.user };
        this.req = await this.$api.post(`${this.$apiHost}/admin/deleteUser`, this.body, this.axios);
        this.deleteUserSuccess(this.req.data);
      } catch (error) {
        this.deleteUserError(error);
      }

      this.isLoading(false);
    },
    deleteUserError: function(data = {}) {
      this.showMessage(data);
    },
    deleteUserSuccess: function(data = {}) {
      if (!data.success) {
        return this.showMessage(data);
      }

      this.modal.close(async () => {
        this.user.deleted = true;
        Go.addClass(`[u="${this.user._id}"]`, "__deleted");

        if (!Go.is(this.onDelete, "Function")) {
          await Go.sleep(100);
          return this.showMessage(data);
        }

        return this.onDelete(async () => {
          await Go.sleep(100);
          this.showMessage(data);
        });
      });
    },
  },
  computed: {
    confirm: function() {
      return {
        accept: {
          label: this.$locale["delete"],
        },
        cancel: {
          label: this.$locale["cancel"],
        },
      };
    },
  },
};
</script>
